/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useContext} from 'react'

import {useFormik} from 'formik'
import './FilterModal.scss'
import * as Yup from 'yup'
import {ModalHeader} from '../../../../modals/ModalHeader'
import Modal from 'react-modal'
import {useVoterStyles} from '../../../../../contexts/VoterStylesContext'
import {AdminDropdown} from '../../../reusable/admin_dropdown/AdminDropdown'
import {useTranslation} from '../../../../../hooks/useTranslation'
import {isDeveloper} from '../../../../../../KeycloakAdminService'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
    width: '1139px',
  },
  overlay: {
    zIndex: '9999',
    background: 'rgba(0, 0, 0, 0.5)',
  },
}

const district = [
  {value: 0, label: 'District'},
  {value: 'Mexico', label: 'Mexico'},
]

const channel = [
  {value: 0, label: 'Channel'},
  {value: 'Online', label: 'Online'},
  {value: 'In-Person', label: 'In-Person'},
]

export const initialValues = {
  district: '',
  channel: '',
}

export const schema = Yup.object().shape({
  district: Yup.string().required('District is required!'),
})

export function FilterModal(props) {
  const {translate} = useTranslation()

  let {headerLabel, isOpen, onRequestClose} = props

  const [startDate, setStartDate] = useState(null)
  const [loading, setLoading] = useState(false)

  const [districtFilter, setDistrictFilter] = useState(props.districtFilter)
  const [channelFilter, setChannelFilter] = useState(props.channelFilter)
  const [statusFilter, setStatusFilter] = useState(props.statusFilter)

  const {customBtnClass} = useVoterStyles()

  const [modalIsOpen, setIsOpen] = useState(false)

  function afterOpenModal() {}
  function onAfterCompleteModal() {}
  function closeModal() {
    setIsOpen(false)
  }

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onAfterOpen={props.onAfterOpen}
      onRequestClose={props.onRequestClose}
      style={customStyles}
    >
      <div className=''>
        <ModalHeader onRequestClose={props.onRequestClose} title={translate('Filter By')} />

        {/* BODY CONTENT */}
        <div className='FilterModal'>
          {isDeveloper() ? (
            <AdminDropdown
              view='mexicoDistricts'
              setDistrictFilter={setDistrictFilter}
              districtFilter={districtFilter}
              value={districtFilter}
            />
          ) : null}

          {props.showChannel && (
            <AdminDropdown
              view='channel'
              setChannelFilter={setChannelFilter}
              channelFilter={channelFilter}
            />
          )}
          {props.showStatus && (
            <AdminDropdown
              view='statusFilter'
              setStatusFilter={setStatusFilter}
              statusFilter={statusFilter}
              value={statusFilter}
            />
          )}
        </div>

        <div className='ModalFooter'>
          <button
            type='submit'
            className={'filterModal__cancelBtn '}
            onClick={props.onRequestClose}
          >
            {translate('Cancel')}
          </button>

          <button
            type='submit'
            className={
              customBtnClass
                ? 'modalFooter__primaryBtn' + ' ' + customBtnClass
                : 'modalFooter__primaryBtn'
            }
            onClick={() => {
              props.onRequestClose()
              props.onUpdateFilter(districtFilter, channelFilter, statusFilter)
            }}
          >
            {translate('Apply')}
          </button>
        </div>
      </div>
    </Modal>
  )
}
