/* eslint-disable jsx-a11y/anchor-is-valid */
import './VoterLandingPage.scss'
import '@djthoms/pretty-checkbox'

import {toAbsoluteUrl} from '../../../helperfunctions/AssetHelpers'
import {useVoterStyles} from '../../../contexts/VoterStylesContext'
import {CustomBtn} from '../../reusable/custom_btn/CustomBtn'
import useMobileView from '../../../hooks/UseMobileView'
import {useHistory} from 'react-router-dom'
import ROUTE_PATHS from '../../../routing/RoutePaths'
import {useTranslation} from '../../../hooks/useTranslation'
import configOptions from '../../../../utils/configOptions'
import {useEffect, useState} from 'react'
import VoterAuthService from '../../../services/auth/VoterAuthService'

export function VoterLandingPage(props) {
  const isMobileView = useMobileView()
  const history = useHistory()
  const {translate, translate2} = useTranslation()

  const {customMobileLogoImg, customBannerImg} = useVoterStyles()

  const [registrationState, setRegistrationState] = useState('closed')

  const getRegState = async () => {
    let res = await VoterAuthService.getRegistrationState()
    let newState = res.registrationOpen ? 'open' : 'closed'
    setRegistrationState(newState)
  }

  useEffect(() => {
    getRegState()
    // clear reg form data
    sessionStorage.removeItem('formData')
    sessionStorage.removeItem('currentStep')
  }, [])

  // if (isMobileView) {
  //   return (
  //     <div className='VoterLandPgMobile'>
  //       <div className='VoterLandPgMobile__content'>
  //         <img
  //           alt='logo'
  //           className='VoterLandPgMobile__content__logo'
  //           src={toAbsoluteUrl('/media/design/logosbanners/hamilton.svg')}
  //         />

  //         <div className='voterLandPgMobile__content__txt'>
  //           <span className='voterLandPgMobile__content__txt__title'>
  //             {translate('National Elections')}
  //           </span>
  //           <span className='voterLandPgMobile__content__txt__title'>2024</span>
  //           {/* <span className='voterLandPgMobile__content__txt__date'>15 Sep 2024</span> */}
  //         </div>
  //         {configOptions.registrationEnabled && registrationState === 'open' && (
  //           <CustomBtn
  //             customStyling={{width: window.innerWidth - 54}}
  //             view='ActionBtn'
  //             text={translate('REGISTER')}
  //             height={50}
  //             onClick={() => history.push(ROUTE_PATHS.REGISTRATION_START)}
  //           />
  //         )}
  //         <CustomBtn
  //           customStyling={{width: window.innerWidth - 54, marginTop: 30}}
  //           view='ActionBtn'
  //           text={translate('LOGIN')}
  //           height={50}
  //           onClick={() => history.push(ROUTE_PATHS.LOGIN)}
  //         />
  //       </div>
  //     </div>
  //   )
  // }

  return (
    <div className='VoterLandingPage' id='Voter'>
      {/* <img alt='banner' className='voterRegister__topBanner' src={customBannerImg} /> */}
      <div className='bannerBackground'>
        <div className='voterLandingPg__body'>
          <div className='voterLandingPg__bodyWrap'>
            <div className='voterLandingPg__bodyContentWrap'>
              {/* title */}
              <div className='voterLandingPg__body__title__heading'>
                {translate2('electionTitleLine1')}
                <br />
                {translate2('electionTitleLine2')}
                <br />
                {translate2('electionTitleLine3')}
              </div>

              {/* how to card */}
              <div className='voterLandingPg__body__howToCard'>
                {/* content */}
                <div className='voterLandingPg__body__howToCard__content'>
                  <div className='voterLandingPg__body__howToCard__content__txt'>
                    <div className='voterLandingPg__body__howToCard__content__txtWrap'>
                      <span className='voterLandingPg__body__howToCard__content__txt__title'>
                        {translate2('howToVote')}
                      </span>
                      {configOptions.registrationEnabled ? (
                        <span className='voterLandingPg__body__howToCard__content__txt__desc'>
                          {translate2('onlineVoterRequirementsOpen')}
                        </span>
                      ) : (
                        <span className='voterLandingPg__body__howToCard__content__txt__desc'>
                          {translate2('onlineVoterRequirements')}
                        </span>
                      )}
                    </div>

                    <img
                      className='voterLandingPg__body__howToCard__content__icon'
                      alt='computer icon'
                      src={toAbsoluteUrl('/media/design/computer.svg')}
                    />
                  </div>
                </div>
                {configOptions.registrationEnabled ? (
                  <>
                    <div className='voterLandingPg__body__howToCard__btnWrap'>
                      <CustomBtn
                        customStyling={{width: 137}}
                        view='ActionBtn'
                        text={translate('Register')}
                        height={50}
                        onClick={() => history.push(ROUTE_PATHS.REGISTRATION_START)}
                      />

                      <span className='voterLandingPg__body__howToCard__btnWrap login-desktop'>
                        <CustomBtn
                          customStyling={{width: 137, marginLeft: 30}}
                          view='PrimaryBtn'
                          text={translate('Login')}
                          height={50}
                          onClick={() => history.push(ROUTE_PATHS.LOGIN)}
                        />
                      </span>
                    </div>
                    <div className='voterLandingPg__body__howToCard__btnWrap login-mobile'>
                      <CustomBtn
                        customStyling={{width: 137, marginTop: 20}}
                        view='PrimaryBtn'
                        text={translate('Login')}
                        height={50}
                        onClick={() => history.push(ROUTE_PATHS.REGISTRATION_START)}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className='voterLandingPg__body__howToCard__btnWrap'>
                      <span className='voterLandingPg__body__howToCard__btnWrap login-desktop'>
                        <CustomBtn
                          customStyling={{width: 137, marginLeft: 30}}
                          view='PrimaryBtn'
                          text={translate('Login')}
                          height={50}
                          onClick={() => history.push(ROUTE_PATHS.LOGIN)}
                        />
                      </span>
                    </div>
                    <div className='voterLandingPg__body__howToCard__btnWrap login-mobile'>
                      <CustomBtn
                        customStyling={{width: 137, marginTop: 20}}
                        view='PrimaryBtn'
                        text={translate('Login')}
                        height={50}
                        onClick={() => history.push(ROUTE_PATHS.REGISTRATION_START)}
                      />
                    </div>
                  </>
                )}
                {/* <span
                className='voterLandingPg__checkStatusLink'
                onClick={() => {
                  history.push('/voting/voter-auth/status')
                }}
              >
                {translate('Check your registration status')}
              </span> */}
              </div>

              {/* help card */}
              <div className='voterLandingPg__body__needHelpCard'>
                <div className='voterLandingPg__body__needHelpCard__subTxt'>
                  <span className='voterLandingPg__body__needHelpCard__subTxt__desc'>
                    {translate2('helpInfo1')}
                  </span>
                  <br />
                  <span className='voterLandingPg__body__needHelpCard__subTxt__desc'>
                    {translate2('helpInfo2')}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
