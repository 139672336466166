import TextField from '../../../../modules/auth/reusable/TextField'
import './AddressInfo.scss'
import '../../pages/VoterRegister.scss'

import {useTranslation} from '../../../../hooks/useTranslation'
import PlacesAutocomplete from '../../../../modules/reusable/GooglePlacesAutocomplete'

export function MailingInfo({formik, view = '', customStyling = {}}) {
  const {translate} = useTranslation()

  const VoterRegister = (
    <>
      <div className='voterRegister__addressWrap'>
        <div className='voterRegister__addressInputWrap'>
          <PlacesAutocomplete
            view='voter'
            formik={formik}
            type='text'
            field='mailingAddress'
            placeholder={translate('Street Address')}
            label={translate('Street Address')}
            isRequired={true}
            types={['address']}
            hideErrorLabel
            mailing
          />
        </div>
        <div>
          <TextField
            formik={formik}
            type='text'
            field='mailingUnitNumber'
            placeholder={translate('Unit Number')}
            label={translate('Unit #/P.O.Box')}
            view='voter'
          />
        </div>
      </div>

      <div className='voterRegister__locationWrap'>
        <div className='voterRegister__locationInputsWrap'>
          <div className='voterRegister__cityInputWrap'>
            <TextField
              formik={formik}
              type='text'
              field='mailingCity'
              placeholder={translate('City')}
              label={translate('City')}
              isRequired={true}
              view='voter'
            />
          </div>

          <div className='voterRegister__provinceDropdownWrap'>
            <TextField
              formik={formik}
              type='text'
              field='mailingProvince'
              placeholder={translate('Province')}
              label={translate('Province')}
              isRequired={true}
              view='voter'
            />
          </div>

          <div className='voterRegister__postalInputWrap'>
            <TextField
              formik={formik}
              type='text'
              field='mailingPostalCode'
              placeholder={translate('Postal Code')}
              label={translate('Postal Code')}
              isRequired={true}
              view='voter'
            />
          </div>
        </div>

        {formik.errors['mailingCity'] && (
          <div className='voterRegister__singleInputErr'>
            <div className='TextField__voterErrWrap'>
              <span className='TextField__voterErrTxt' role='alert'>
                {translate(formik.errors['mailingCity'])}
              </span>
            </div>
          </div>
        )}
        {formik.errors['mailingProvince'] && (
          <div className='voterRegister__singleInputErr'>
            <div className='TextField__voterErrWrap'>
              <span className='TextField__voterErrTxt' role='alert'>
                {translate(formik.errors['mailingProvince'])}
              </span>
            </div>
          </div>
        )}
        {formik.errors['mailingPostalCode'] && (
          <div className='voterRegister__singleInputErr'>
            <div className='TextField__voterErrWrap'>
              <span className='TextField__voterErrTxt' role='alert'>
                {translate(formik.errors['mailingPostalCode'])}
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  )
  const renderView = () => {
    return VoterRegister
  }

  return (
    <div className='voterRegister__addressRow' style={customStyling}>
      <div className='voterRegister__divider'>
        <span className={'voterRegister__dividerLabel'}>{translate('Mailing Address')}</span>
      </div>

      {renderView()}
    </div>
  )
}
