export const getDisplayAdress = (address) => {
  if (address.formattedAddress) {
    return address.formattedAddress
  }
  if (address.streetAddress?.includes(',')) {
    return address.streetAddress
  }
  return `${address.unitNumberPOBox ? address.unitNumberPOBox + '-' : ''}${
    address.streetAddress
  }, ${address.city}, ${address.province}, ${address.postalCode}`
}
