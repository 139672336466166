/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'

import {AddressInfo} from '../../components/registration/AddressInfo'
import {MailingInfo} from '../../components/registration/MailingInfo'
import {UploadPhotoID} from '../../components/registration/UploadPhotoID'
import '../VoterRegister.scss'
import '../../reusable/modals/VoterRegModals.scss'

import CheckBox from '../../../reusable/check_box/CheckBox'
import {CustomBtn} from '../../../reusable/custom_btn/CustomBtn'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {useTranslation} from '../../../../hooks/useTranslation'

export function VoterRegisterStep2(props) {
  const {view, setCurrentStep, formik, currentSchema, hasSingleName} = props
  const {translate} = useTranslation()

  const [loading, setLoading] = useState(false)

  const [acceptPropertyTerms, setAcceptPropertyTerms] = useState(
    formik.values.hasMailing ? true : false
  )

  const handlePropertyTerms = (value) => {
    setAcceptPropertyTerms(value)
    formik.setFieldValue('hasMailing', value)

    console.log('value', value)
    //  CHANGE SCHEMA
    props.changeSchema(value == true ? 'step2--full' : 'step2--default')
  }

  useEffect(() => {
    if (formik.values.photoID) {
      formik.setFieldError('photoID', '')
    }
  }, [formik.values.photoID, formik.errors.photoID])

  return (
    <>
      <CheckBox
        checked={acceptPropertyTerms}
        onChange={handlePropertyTerms}
        error={null}
        label='Mailing address is different from new qualifying address' // TODO translation
      />

      {/* Current Residential Address  --------- */}
      <AddressInfo
        formik={formik}
        // setModalView={setModalView}
        // setIsOpen={setIsOpen}
        view='VoterRegister'
      />

      {/* Ignace Property Address ---------  */}
      {acceptPropertyTerms && <MailingInfo formik={formik} view='VoterRegister' />}

      <hr className='voterRegister__uploadDividerBottom'></hr>

      <hr className='voterRegister__uploadDividerTop'></hr>

      <div className='voterRegister__footerWrap'>
        {/* <CustomBtn view='PrimaryBtn' text='Clear Form' height={50} onClick={() => {}} /> */}

        <CustomBtn
          customStyling={{marginRight: 25}}
          view='PrimaryBtn'
          text={translate('Back')}
          height={50}
          type='button'
          onClick={() => {
            if (hasSingleName) {
              props.changeSchema('step1--singleName')
            } else {
              props.changeSchema('step1')
            }

            setCurrentStep(1)
          }}
        />

        <CustomBtn
          view='ActionBtn'
          text={translate('Update')}
          height={50}
          onClick={() => {
            formik.submitForm()
          }}
          disabled={loading}
          type='button'
        />
      </div>
    </>
  )
}
