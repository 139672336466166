import {useRef, useEffect, useState} from 'react'
import {useTranslation} from '../../hooks/useTranslation'

const AutoComplete = (props) => {
  const {
    formik,
    field,
    placeholder,
    label,
    isRequired,
    noLabel,
    types = [],
    width = '100%',
    view = 'default',
    onSelect,
    hideErrorLabel,
    mailing = false,
  } = props
  const {translate} = useTranslation()
  const autoCompleteRef = useRef()
  const inputRef = useRef()
  const options = {
    componentRestrictions: {country: 'ca'},
    fields: ['address_components', 'geometry', 'icon', 'name'],
  }

  function fillInAddress(place) {
    // Get the place details from the autocomplete object.
    let address1 = ''
    let postcode = ''

    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    // place.address_components are google.maps.GeocoderAddressComponent objects
    // which are documented at http://goo.gle/3l5i5Mr
    for (const component of place.address_components) {
      // @ts-ignore remove once typings fixed
      const componentType = component.types[0]

      switch (componentType) {
        case 'street_number': {
          address1 = `${component.long_name} ${address1}`
          break
        }

        case 'route': {
          address1 += component.short_name
          break
        }

        case 'postal_code': {
          postcode = `${component.long_name}${postcode}`

          break
        }

        case 'postal_code_suffix': {
          postcode = `${postcode}-${component.long_name}`
          break
        }
        case 'locality':
          // document.querySelector("#locality").value = ;
          if (mailing) {
            formik.setFieldValue('mailingCity', component.long_name)
          } else {
            formik.setFieldValue('city', component.long_name)
          }
          break
        case 'administrative_area_level_1': {
          // document.querySelector('#state').value = component.short_name
          if (mailing) {
            formik.setFieldValue('mailingProvince', component.short_name)
          } else {
            formik.setFieldValue('province', component.short_name)
          }
          break
        }
        case 'country':
          // document.querySelector('#country').value = component.long_name
          if (mailing) {
            formik.setFieldValue('mailingCountry', component.long_name)
          } else {
            formik.setFieldValue('country', component.long_name)
          }

          break
        default:
          break
      }
      if (postcode) {
        if (mailing) {
          formik.setFieldValue('mailingPostalCode', postcode)
        } else {
          formik.setFieldValue('postalCode', postcode)
        }
      }
      if (address1) {
        if (mailing) {
          formik.setFieldValue('mailingAddress', address1)
        } else {
          formik.setFieldValue('streetAddress', address1)
        }
      }
    }
    console.log('address', address1)

    //   address1Field.value = address1;
    //   postalField.value = postcode;
    //   // After filling the form with address components from the Autocomplete
    //   // prediction, set cursor focus on the second address line to encourage
    //   // entry of subpremise information such as apartment, unit, or floor number.
    //   address2Field.focus();
  }

  // Function to handle input change
  const handleInputChange = (e) => {
    const value = e.target.value
    console.log('value', value)

    if (value === '') {
      formik.setFieldError(field, 'Please select an address from the dropdown.')
      if (mailing) {
        formik.setFieldValue('mailingAddress', '')
      } else {
        formik.setFieldValue('streetAddress', '')
      }
    } else {
      if (mailing) {
        formik.setFieldValue('mailingAddress', value)
      } else {
        formik.setFieldValue('streetAddress', value)
      }
      formik.setFieldError(field, undefined) // Clear the error if selection has been made
    }
  }

  useEffect(() => {
    //@ts-ignore
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, options)
    //@ts-ignore
    autoCompleteRef.current.addListener('place_changed', async function () {
      //@ts-ignore
      const place = await autoCompleteRef.current.getPlace()
      console.log({place})
      try {
        fillInAddress(place)
      } catch (error) {}
    })
  }, [])

  return (
    <div>
      {!noLabel && (
        <label
          className={
            isRequired
              ? 'form-label fs-6 fw-bold text-dark required'
              : 'form-label fs-6 fw-bold text-dark'
          }
        >
          {label}
        </label>
      )}

      {mailing ? (
        <input
          {...formik.getFieldProps('mailingAddress')}
          className='SelectField__voter'
          ref={inputRef}
          onChange={handleInputChange}
          name='mailingAddress'
          value={formik.values.mailingAddress}
        />
      ) : (
        <input
          {...formik.getFieldProps('streetAddress')}
          className='SelectField__voter'
          ref={inputRef}
          onChange={handleInputChange}
          name='streetAddress'
          value={formik.values.streetAddress}
        />
      )}

      {formik.touched[field] && formik.errors[field] && (
        <div className='voterRegister__singleInputErr'>
          <div className='TextField__voterErrWrap'>
            <span className='TextField__voterErrTxt' role='alert'>
              {translate(formik.errors[field])}
            </span>
          </div>
        </div>
      )}
    </div>
  )
}
export default AutoComplete
