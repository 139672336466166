/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useContext} from 'react'
import {useHistory} from 'react-router-dom'
import {useFormik} from 'formik'

import './VoterRegister.scss'
import {ModalWrapper} from '../reusable/modals/modal_wrapper/ModalWrapper'
import '../reusable/modals/VoterRegModals.scss'
import {
  step1SchemaEdit,
  step1SchemaEditWithSingleName,
  step2SchemaDefault,
  step2SchemaDefaultAdmin,
  step2SchemaDefaultAdminEdit,
  step2SchemaSecondaryAdminEdit,
} from './VoterRegisterSchema2'
import {useVoterStyles} from '../../../contexts/VoterStylesContext'
import VoterRegisterHeader from '../components/layout/VoterRegisterHeader'

import BackgroundBanner from '../../reusable/background_banner/BackgroundBanner'
import {VoterRegisterStep1} from './step_1/EligibleEditStep1'
import {VoterRegisterStep2} from './step_2/EligibleEditStep2'
import {VoterRegistrationUserContext} from '../../../contexts/RegistrationUser'
import AdminVoterService from '../../../services/admin/AdminVoterService'
import VoterRegistrationService from '../services/VoterRegistrationService'
import {useTranslation} from '../../../hooks/useTranslation'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
}

export function EligibleEdit(props) {
  const {user} = props.history.location.state

  const initialValues = {
    // USER INFO
    firstName: user.firstName,
    middleName: user.middleName,
    lastName: user.lastName,
    singleName: user.singleName,

    // DATE OF BIRTH
    birthMonth: parseInt(user.monthBorn),
    birthDay: parseInt(user.dayBorn),
    birthYear: parseInt(user.yearBorn),

    // RESIDENTIAL ADDRESS
    streetAddress: user.address.streetAddress,
    city: user.address.city,
    province: user.address.province,
    postalCode: user.address.postalCode,
    unitNumber: user.address.unitNumberPOBox,

    // MAILING ADDRESS
    mailingAddress: '',
    mailingCity: '',
    mailingProvince: '',
    mailingPostalCode: '',
    mailingUnitNumber: '',
  }

  const [currentStep, setCurrentStep] = useState(1)
  const {translate} = useTranslation()

  const [loading, setLoading] = useState(false)
  const [modalIsOpen, setIsOpen] = useState(false)
  const [modalView, setModalView] = useState('')

  const [hasSingleName, setHasSingleName] = useState(user.singleName)
  const RegisterUser = useContext(VoterRegistrationUserContext)
  // CURRENT SHCEMA --
  const [currentSchema, setCurrentSchema] = useState(
    initialValues.singleName ? step1SchemaEditWithSingleName : step1SchemaEdit
  )
  const [eligibleError, setEligibleError] = useState('')

  const history = useHistory()

  const isAdminView = true

  const formik = useFormik({
    initialValues,
    validationSchema: currentSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(async () => {
        try {
          setEligibleError('')
          // if step 1 is succesful
          if (currentStep == 1) {
            console.log('step 1 success')
            setCurrentSchema(step2SchemaDefaultAdminEdit as any)

            setCurrentStep(2)
          }

          if (currentStep == 2) {
            const userInput = {
              // STEP 1 --------------------------------
              // USER INFO
              firstName: values.firstName?.trim(),
              middleName: values.middleName?.trim(),
              lastName: values.lastName?.trim(),
              singleName: values.singleName ? true : false,
              // BIRTH DAYE
              birthMonth: values.birthMonth,
              birthDay: values.birthDay,
              birthYear: values.birthYear,
              dateOfBirth: new Date(`${values.birthDay} ${values.birthMonth} ${values.birthYear}`),

              // STEP 2 -------------------------------------------
              // RESIDENTIAL ADDRESS
              address: {
                streetAddress: values.streetAddress?.trim(),
                city: values.city?.trim(),
                province: values.province?.trim(),
                postalCode: values.postalCode?.trim(),
                unitNumberPOBox: values.unitNumber?.trim(),
              },

              // MAILING ADDRESS
              mailingAddress: {
                streetAddress: values.mailingAddress,
                city: values.mailingCity,
                province: values.mailingProvince,
                postalCode: values.mailingPostalCode,
                unitNumberPOBox: values.mailingUnitNumber,
              },
            }
            // todo submit
            let res = await AdminVoterService.updateEligible(user._id, userInput)
            if (res?.success) {
              alert('Successfully updated')
              history.goBack()
            } else {
              alert('Something went wrong, please contact tech support.')
            }

            setLoading(false)
          }
        } catch (error) {
          console.log(error)
        }
      }, 10)
    },
  })

  function closeModal() {
    setIsOpen(false)
  }

  // useEffect(() => {
  //   if (photoID) {
  //     setIsPhotoError(false)
  //   }
  // }, [photoID])

  const renderView = () => {
    if (currentStep == 1) {
      return (
        <VoterRegisterStep1
          formik={formik}
          setCurrentStep={setCurrentStep}
          hasSingleName={hasSingleName}
          setHasSingleName={setHasSingleName}
          changeSchema={(newSchema) => {
            if (newSchema == 'step1') {
              console.log('step1')
              setCurrentSchema(step1SchemaEdit as any)
            }

            if (newSchema == 'step1--singleName') {
              console.log('step1--singleName')
              setCurrentSchema(step1SchemaEditWithSingleName as any)
            }
          }}
          isAdminView={true}
        />
      )
    }

    if (currentStep == 2) {
      return (
        <VoterRegisterStep2
          formik={formik}
          currentSchema={currentSchema}
          setCurrentStep={setCurrentStep}
          hasSingleName={hasSingleName}
          changeSchema={(newSchema) => {
            if (newSchema == 'step1') {
              console.log('step1')
              setCurrentSchema(step1SchemaEdit as any)
            }

            if (newSchema == 'step1--singleName') {
              console.log('step1--singleName')
              setCurrentSchema(step1SchemaEditWithSingleName as any)
            }

            if (newSchema == 'step2-default') {
              setCurrentSchema(step2SchemaDefaultAdminEdit as any)
            }

            if (newSchema == 'step2-full') {
              setCurrentSchema(step2SchemaSecondaryAdminEdit as any)
            }
          }}
          isAdminView={true}
        />
      )
    }
  }

  useEffect(() => {
    // console.log('formik.errors', formik.errors)
  }, [formik.errors])

  return (
    <div className='VoterRegister' id='Voter'>
      {/* MODAL */}
      <ModalWrapper
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        modalView={modalView}
      />

      <div className='voterRegister__formWrapper'>
        <BackgroundBanner />

        <form
          className='voterRegister__form'
          onSubmit={(e) => {
            e.preventDefault()

            formik.submitForm()
          }}
        >
          {/* VIEW */}
          {renderView()}
        </form>
      </div>
    </div>
  )
}
