const configOptions = {
  timeZoneDateString: '',
  currentTimeInString: 'Current Time',
  shortTimeString: '',
  disableImportElectors: true,
  registrationEnabled: new Date() < new Date('Jan 6 2025 00:00:00 GMT-0500'),
  customSecurityAnswer: null,
  securityAnswerLength: 4,
  usernameStartsWith: '5555',
  voteReceiptEnabled: true, // todo
}
export default configOptions
