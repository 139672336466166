/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'

import {AddressInfo} from '../../components/registration/AddressInfo'
import {MailingInfo} from '../../components/registration/MailingInfo'
import {UploadPhotoID} from '../../components/registration/UploadPhotoID'
import '../VoterRegister.scss'
import '../../reusable/modals/VoterRegModals.scss'

import CheckBox from '../../../reusable/check_box/CheckBox'
import {CustomBtn} from '../../../reusable/custom_btn/CustomBtn'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {useTranslation} from '../../../../hooks/useTranslation'

export function VoterRegisterStep2(props) {
  const {
    view,
    setCurrentStep,
    formik,
    currentSchema,
    hasSingleName,
    setEligibleError,
    isAdminView,
  } = props
  const {translate} = useTranslation()

  const [loading, setLoading] = useState(false)

  const [singleName, setSingleName] = useState(false)

  const [modalIsOpen, setIsOpen] = useState(false)
  const [modalView, setModalView] = useState('')

  const [addAnother, setAddAnother] = useState(formik.values.addAnother ? true : false)

  const [termsError, setTermsError] = useState(false)
  const [voterTerms, setVoterTerms] = useState(false)

  const [acceptPropertyTerms, setAcceptPropertyTerms] = useState(formik.values.voterTerms)

  const handleVoterTerms = (value) => {
    setVoterTerms(value)
    formik.setFieldValue('voterTerms', value)

    if (formik.errors.voterTerms) {
      formik.setFieldError('voterTerms', '')
    }

    setTermsError(false)
  }

  const handlePropertyTerms = (value) => {
    setAcceptPropertyTerms(value)

    console.log('value', value)
    //  CHANGE SCHEMA
    props.changeSchema(value == true ? 'step2--full' : 'step2--default')
  }

  useEffect(() => {
    setVoterTerms(formik.values.voterTerms)
  }, [formik.values.voterTerms])

  useEffect(() => {
    if (formik.values.photoID) {
      formik.setFieldError('photoID', '')
    }
  }, [formik.values.photoID, formik.errors.photoID])

  return (
    <>
      {/* <CheckBox
        checked={acceptPropertyTerms}
        onChange={handlePropertyTerms}
        error={null}
        label='My mailing address is different from my residential address'
      /> */}

      {/* Current Residential Address  --------- */}
      <AddressInfo
        formik={formik}
        // setModalView={setModalView}
        // setIsOpen={setIsOpen}
        view='VoterRegister'
      />

      {/* Ignace Property Address ---------  */}
      {/* {acceptPropertyTerms && <MailingInfo formik={formik} />} */}

      {/* Upload Photo ID */}
      <UploadPhotoID
        formik={formik}
        setModalView={setModalView}
        setIsOpen={setIsOpen}
        // setPhotoID={setPhotoID}
        formikValueName='photoID'
        isAdminView={isAdminView}
        // photoURL={photoURL}
      />

      <div
        className='uploadPhotoID__anotherDocBtn'
        onClick={() => {
          setAddAnother(!addAnother)
          formik.setFieldValue('addAnother', !addAnother)
        }}
      >
        <img
          alt='plus circle icon'
          className=''
          src={toAbsoluteUrl('/media/design/icon_plus_circle_outlined.svg')}
        />
        <span className='uploadPhotoID__anotherDocBtn__label'>
          {translate('Add Another Document')}
        </span>
      </div>
      {addAnother && (
        <UploadPhotoID
          formik={formik}
          setModalView={setModalView}
          setIsOpen={setIsOpen}
          // setPhotoID={setPhotoID}
          formikValueName='photoID2'
          // photoURL={photoURL}
        />
      )}
      {/* TODO: buggy */}
      {formik.errors['photoID'] == 'Photo id is required' && (
        <div className='voterRegister__singleInputErr'>
          <div className='TextField__voterErrWrap'>
            <span className='TextField__voterErrTxt' role='alert'>
              {translate('Upload at least one piece of identification!')}
            </span>
          </div>
        </div>
      )}

      <hr className='voterRegister__uploadDividerBottom'></hr>

      <CheckBox
        checked={voterTerms}
        onChange={handleVoterTerms}
        error={termsError && translate('You have to declare that you are an eligible voter')}
        label={translate(
          'I declare that I either reside or own property with the city of Hamilton (ward 4) and 18 years of age.'
        )}
      />

      {formik.errors['voterTerms'] && (
        <div className='voterRegister__singleInputErr'>
          <div className='TextField__voterErrWrap'>
            <span className='TextField__voterErrTxt' role='alert'>
              {translate('You must accept terms!')}
            </span>
          </div>
        </div>
      )}

      <hr className='voterRegister__uploadDividerTop'></hr>

      <div className='voterRegister__footerWrap'>
        {/* <CustomBtn view='PrimaryBtn' text='Clear Form' height={50} onClick={() => {}} /> */}

        <CustomBtn
          customStyling={{marginRight: 25}}
          view='PrimaryBtn'
          text={translate('Back')}
          height={50}
          type='button'
          onClick={() => {
            setEligibleError('')
            if (hasSingleName) {
              props.changeSchema('step1--singleName')
            } else {
              props.changeSchema('step1')
            }

            setCurrentStep(1)
          }}
        />

        <CustomBtn
          view='ActionBtn'
          text={translate('Register')}
          height={50}
          onClick={() => {
            formik.submitForm()
          }}
          disabled={loading}
          type='button'
        />
      </div>
    </>
  )
}
