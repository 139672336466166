/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useContext} from 'react'
import {useHistory} from 'react-router-dom'
import {useFormik} from 'formik'

import './VoterRegister.scss'
import {ModalWrapper} from '../reusable/modals/modal_wrapper/ModalWrapper'
import '../reusable/modals/VoterRegModals.scss'
import {
  initialValues,
  step1Schema,
  step1SchemaWithSingleName,
  step2SchemaDefault,
  step2SchemaDefaultAdmin,
  step2SchemaSecondary,
  step2SchemaSecondaryAdmin,
} from './VoterRegisterSchema2'
import {useVoterStyles} from '../../../contexts/VoterStylesContext'
import VoterRegisterHeader from '../components/layout/VoterRegisterHeader'

import Banner from '../../reusable/background_banner/BackgroundBanner'
import BackgroundBanner from '../../reusable/background_banner/BackgroundBanner'
import {VoterRegisterStep1} from './step_1/VoterRegisterStep1'
import {VoterRegisterStep2} from './step_2/VoterRegisterStep2'
import {VoterRegistrationUserContext} from '../../../contexts/RegistrationUser'
import AdminVoterService from '../../../services/admin/AdminVoterService'
import VoterRegistrationService from '../services/VoterRegistrationService'
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3'
import {useTranslation} from '../../../hooks/useTranslation'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
}

export function VoterRegister(props) {
  const {view} = props

  const [currentStep, setCurrentStep] = useState(1)
  const {translate} = useTranslation()

  const [loading, setLoading] = useState(false)
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [contactsTermsErr, setContactTermsError] = useState(false)
  const [modalIsOpen, setIsOpen] = useState(false)
  const [modalView, setModalView] = useState('')

  const [hasSingleName, setHasSingleName] = useState(false)
  const RegisterUser = useContext(VoterRegistrationUserContext)
  // CURRENT SHCEMA --
  const [currentSchema, setCurrentSchema] = useState(step1Schema)
  const [eligibleError, setEligibleError] = useState('')

  const history = useHistory()

  const {customBtnClass, customTxtClass, customBannerImg} = useVoterStyles()
  const {executeRecaptcha} = useGoogleReCaptcha()
  const isAdminView = window.location.pathname.includes('admin')

  const submitAdmin = async (formData) => {
    let result = await AdminVoterService.registerVoter(formData)
    if (result.success) {
      let data = result.data
      history.replace(`/admin/voters/${data.voterIdentifier}`)
    } else {
      alert('An error occurred')
    }
  }

  useEffect(() => {
    setEligibleError('')
  }, [])

  const formik = useFormik({
    initialValues,
    validationSchema: currentSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(async () => {
        try {
          setEligibleError('')
          // if step 1 is succesful
          if (currentStep == 1) {
            console.log('step 1 success')
            if (isAdminView) {
              setCurrentSchema(step2SchemaDefaultAdmin as any)
            } else {
              setCurrentSchema(step2SchemaDefault as any)
            }
            setCurrentStep(2)
          }

          if (currentStep == 2) {
            // console.log('photoID', photoID)
            // if (photoID == null) {
            //   console.log('ERROR: photo ID not uploaded!')
            //   setIsPhotoError(true)
            //   return
            // }

            const userInput = {
              // STEP 1 --------------------------------
              // USER INFO
              firstName: values.firstName?.trim(),
              middleName: values.middleName?.trim(),
              lastName: values.lastName?.trim(),
              singleName: values.singleName ? true : false,
              // BIRTH DAYE
              birthMonth: values.birthMonth,
              birthDay: values.birthDay,
              birthYear: values.birthYear,
              dateOfBirth: new Date(`${values.birthDay} ${values.birthMonth} ${values.birthYear}`),

              // CONTACT INFO
              email: values.email?.trim(),
              confirmEmail: values.confirmEmail,
              phone: values.phoneNumber,

              // RECEIVE SMS
              receiveSMSNotif: values.receiveSMSNotif,

              // STEP 2 -------------------------------------------
              // RESIDENTIAL ADDRESS
              address: {
                streetAddress: values.streetAddress?.trim(),
                city: values.city?.trim(),
                province: values.province?.trim(),
                postalCode: values.postalCode?.trim(),
                unitNumberPOBox: values.unitNumber?.trim(),
              },

              // MAILING ADDRESS
              mailingAddress: {
                streetAddress: values.mailingAddress,
                city: values.mailingCity,
                province: values.mailingProvince,
                postalCode: values.mailingPostalCode,
                unitNumberPOBox: values.mailingUnitNumber,
              },

              // Q/ TERMS: Have you moved to or purchased property in Ignace since November 2023?
              // movedOrPurchacedThisYear: values.propertyTerms ? values.propertyTerms : false,

              // photoIDs: [values.photoID, values.photoID2],
            }
            // console.log(userInput)
            const formData = new FormData()
            for (var key in userInput) {
              if (key === 'address' || key === 'mailingAddress') {
                // append nested object
                for (let addressKey in userInput[key]) {
                  formData.append(`${key}[${addressKey}]`, userInput[key][addressKey])
                }
              } else {
                formData.set(key, userInput[key])
              }
            }

            formData.append('photoIDs', values.photoID)
            values.photosList = [values.photoID]
            if (values.photoID2) {
              formData.append('photoIDs', values.photoID2)
              values.photosList.push(values.photoID2)
            }

            const token = await executeRecaptcha('register_participant')
            let eligible = await VoterRegistrationService.checkEligible(formData)
            if (eligible.success) {
              if (isAdminView) {
                // submit form admin
                submitAdmin(formData)
              } else {
                RegisterUser.setRegistrationUser({formData, user: userInput})
                history.push('/voting/register-complete')
              }
            } else {
              if (eligible.error.message === 'recaptcha error') {
                setEligibleError('Recaptcha error')
              } else {
                if (isAdminView) {
                  setEligibleError(
                    'User has already voted (in person or online), registered to vote online, or is no longer on the electors list.'
                  )
                } else {
                  setEligibleError(
                    'You have already voted (in person or online), already registered to vote online, or are no longer on the electors list. Please check your email for updates on your registration if registered. Please contact us at elections@hamilton.ca with any questions.'
                  )
                }
              }
            }

            // }

            setLoading(false)
          }
        } catch (error) {
          console.log(error)
        }
      }, 10)
    },
  })

  function afterOpenModal() {}

  function onAfterCompleteModal() {}

  function closeModal() {
    setIsOpen(false)
  }

  // useEffect(() => {
  //   if (photoID) {
  //     setIsPhotoError(false)
  //   }
  // }, [photoID])

  const renderView = () => {
    if (currentStep == 1) {
      return (
        <VoterRegisterStep1
          formik={formik}
          setCurrentStep={setCurrentStep}
          contactsTermsErr={contactsTermsErr}
          hasSingleName={hasSingleName}
          setHasSingleName={setHasSingleName}
          changeSchema={(newSchema) => {
            if (newSchema == 'step1') {
              console.log('step1')
              setCurrentSchema(step1Schema as any)
            }

            if (newSchema == 'step1--singleName') {
              console.log('step1--singleName')
              setCurrentSchema(step1SchemaWithSingleName as any)
            }
          }}
        />
      )
    }

    if (currentStep == 2) {
      return (
        <VoterRegisterStep2
          formik={formik}
          currentSchema={currentSchema}
          setCurrentStep={setCurrentStep}
          hasSingleName={hasSingleName}
          setEligibleError={setEligibleError}
          changeSchema={(newSchema) => {
            if (newSchema == 'step1') {
              console.log('step1')
              setCurrentSchema(step1Schema as any)
            }

            if (newSchema == 'step1--singleName') {
              console.log('step1--singleName')
              setCurrentSchema(step1SchemaWithSingleName as any)
            }

            if (newSchema == 'step2-default') {
              console.log('step2-default')
              if (isAdminView) {
                setCurrentSchema(step2SchemaDefaultAdmin as any)
              } else {
                setCurrentSchema(step2SchemaDefault as any)
              }
            }

            if (newSchema == 'step2-full') {
              console.log('step2-full')
              if (isAdminView) {
                setCurrentSchema(step2SchemaSecondaryAdmin as any)
              } else {
                setCurrentSchema(step2SchemaSecondary as any)
              }
            }
          }}
          isAdminView={isAdminView}
        />
      )
    }
  }

  useEffect(() => {
    console.log('formik.errors', formik.errors)
  }, [formik.errors])

  return (
    <div className='VoterRegister' id='Voter'>
      {/* MODAL */}
      <ModalWrapper
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        modalView={modalView}
      />

      <div className='voterRegister__formWrapper'>
        <BackgroundBanner />

        <form
          className='voterRegister__form'
          onSubmit={(e) => {
            e.preventDefault()
            if (acceptTerms) {
              // submit
              formik.submitForm()
            } else {
              // setContactTermsError(true)
              // console.log('YOU must accept terms')
            }
          }}
        >
          <VoterRegisterHeader setModalView={setModalView} setIsOpen={setIsOpen} />

          {/* VIEW */}
          {renderView()}
          {!eligibleError ? null : (
            <div className='voterRegister__singleInputErr'>
              <div className='TextField__voterErrWrap'>
                <span className='TextField__voterErrTxt' role='alert'>
                  {translate(eligibleError)}
                </span>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  )
}
