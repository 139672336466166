export const translation = {
  registration: {
    electionTitleLine1: '2025 Hamilton Wentworth',
    electionTitleLine2: 'District School Board',
    electionTitleLine3: 'Ward 4 Trustee By-Election',
    voterRegistration: 'Voter Registration',
    howToVote: 'How to vote online?',
    onlineVoterRequirements: 'Registration for online voting is now closed.',
    onlineVoterRequirementsOpen:
      'Online voters are required to complete a short registration form. Registration is open until January 5, 2025.',
    helpInfo1: `
    The City of Hamilton collects information under the authority of Section 227 of the
Municipal Act, 2001 and section 52(1) of the Municipal Elections Act, 1996 and Ontario
Reg. 304/13. Any personal information collected for online voter registration will be used
to for the purpose of confirming voter eligibility. Information collected for this initiative
may be stored on servers located in Canada and may be subject to Canadian laws. All
identification uploaded to the secure portal, will be deleted by Elections staff upon
determination of voter eligibility. `,
    helpInfo2: `Questions about the collection of this personal information can be directed to the
Manager, Elections and Print & Mail, 71 Main Street West, Hamilton ON L8P 4YS, 905-
546-2424 ext.2753.`,
    clickToBegin: 'Click to begin Registration',
    registerNow: 'Register Now!',
    registrationOpen: 'Registration will Open in',
    ifAnyQuestion:
      'If you have any questions, you can contact us using the contact us at our phone numbers or email address.',
    contactPhone: 'Phone: 905-546-4408',
    registerForParticipation:
      'The following information is required to register for participation in the Igance willingness study.',
    singleName: 'Single Name? (I have a legally registered single name)',
    contactInformation: 'Contact Information',
    iDeclare: 'I declare that I am an eligible voter in this election',
    currentResAddress: 'Current Residential Address',
    searchAddress: 'Search Address',
    typeAnAddress: 'Type an address...',
    addressNotListed: 'My address not listed I will add manually',
    uploadProof: 'Upload proof of identity',
    uploadSupportingDoc: 'Upload Supported Document',
    acceptDocs: 'Accepted identity & supported documents',
    delcareResidence:
      'I declare that I either reside or own property within the city of Hamilton (ward 4) and am 18 years of age.',
    declareAge: 'You must declare that you reside or own property and are 18 years or older.',
    proofRequired: 'Proof of identity is required',
    documentRequired: 'Supported document is required',
    oneMoreStep: 'One More Step !',
    weWillSendCode:
      'We will send a verification code to you, select where you would like the code to be sent.',
    sendToEmail: 'Send to email my address (Email)',
    sendToPhone: 'Send to my phone (SMS)',
    enterCode: 'Enter Code!',
    weSentCode: 'We sent a 6-digit verification code to your',
    pleaseEnterCode: 'Please enter the code below, it will expire after five minutes.',
    didntRecieveCode: 'Didn’t receive the code?',
    resend: 'Resend',
    wrongEmail: 'Wrong email address?',
    successfullyRegistered: 'Successfully Registered',
    successfullAccountMsg:
      'Your account has been successfully created! Please wait for election admin approval. Once approved, you can proceed with the onboarding process.',
  },
}
