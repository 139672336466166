import {useContext, useEffect, useState} from 'react'
import Modal from 'react-modal'
import {toAbsoluteUrl} from '../../../../../../helperfunctions/AssetHelpers'
import TextField from '../../../../../../modules/auth/reusable/TextField'
import {ModalHeader} from '../../../../../modals/ModalHeader'
import {ModalBtn} from '../../../../../modals/voter_modal/ModalBtn'
import '../edit_user_drodown/EditUserModal.scss'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import AdminVoterService from '../../../../../../services/admin/AdminVoterService'
import CustomAdminDropdown from '../../../../reusable/admin_dropdown/AdminFormDropDown'
import {useTranslation} from '../../../../../../hooks/useTranslation'
import {useHistory} from 'react-router-dom'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
    padding: 0,
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 3,
    overflowY: 'auto',
  },
}

export function RemoveVoterModal(props) {
  const {translate, translateObj} = useTranslation()
  const history = useHistory()

  const {userToEdit, modalView} = props

  const [reason, setReason] = useState('')
  const [updateErr, setupdateErr] = useState(false)
  const [loading, setLoading] = useState(false)

  const onSubmit = async () => {
    if (reason) {
      let res = await AdminVoterService.removeEligible(userToEdit._id, {reason})
      if (res.success) {
        alert('Success')
        props.onRequestClose()
      } else {
        setupdateErr(true)
      }
    }
  }

  useEffect(() => {
    setupdateErr(false)
    setLoading(false)
    setReason('')

    Modal.setAppElement('#Voter')
  }, [props.isOpen])

  return (
    <Modal
      ariaHideApp={false}
      isOpen={props.isOpen}
      onAfterOpen={props.onAfterOpen}
      onRequestClose={props.onRequestClose}
      style={customStyles}
    >
      <div className='ModalWrapper' style={{flexGrow: 1}}>
        <ModalHeader
          onRequestClose={props.onRequestClose}
          title={`${translate('Remove')} ${userToEdit.fullName}`}
        />

        {/* BODY */}
        <div className='EditUserModal'>
          <CustomAdminDropdown
            options={[
              {value: 'deceased', label: 'Deceased'},
              {value: 'moved', label: 'Moved'},
              {value: 'other', label: 'Other'},
            ]}
            title='Reason'
            value={reason}
            setValue={setReason}
            isRequired={true}
            isForm
          />

          {updateErr && (
            <div className='voterRegister__singleInputErr'>
              <div className='TextField__voterErrWrap'>
                <span className='TextField__voterErrTxt' role='alert'>
                  {translate(
                    'Error removing user. Please ensure this user has not already voted or registered to vote online.'
                  )}
                </span>
              </div>
            </div>
          )}
        </div>

        <div className='ModalFooter'>
          <ModalBtn
            view='CancelBtn'
            text={translate('Cancel')}
            onClick={() => {
              props.onRequestClose()
            }}
          />

          <ModalBtn
            view='ActionBtn'
            disabled={loading}
            text={translate('Remove')}
            onClick={() => {
              onSubmit()
            }}
          />
        </div>
      </div>
    </Modal>
  )
}
